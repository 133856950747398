<template>
    <div>
        <van-empty v-if="!WCBJ" description="报警已删除" />
        <el-card v-else class="card" :body-style="{ padding: '5px' }">
            <van-cell title="设置差值(℃)" title-class="font" value-class="fontsize">{{ wxcW81_BJ_WCBJ.chaZhi }}</van-cell>
            <van-cell-group inset style="margin:5px 0">
                <van-collapse v-model="activeNames">
                    <van-collapse-item title="传感器1" name="1">
                        {{ WCBJ.sensor1Name }}</van-collapse-item>
                </van-collapse>
                <van-cell title="传感器1时间">{{ WCBJ.dataTime1 }}</van-cell>
                <van-cell title="传感器1温度(℃)" title-class="font" value-class="fontsize">{{ WCBJ.temperature1 }}</van-cell>
            </van-cell-group>
            <van-cell-group inset style="margin:5px 0">
                <van-collapse v-model="activeNames">
                    <van-collapse-item title="传感器2" name="2">
                        {{ WCBJ.sensor2Name }}</van-collapse-item>
                </van-collapse>
                <van-cell title="传感器2时间">{{ WCBJ.dataTime2 }}</van-cell>
                <van-cell title="传感器2温度(℃)" title-class="font" value-class="fontsize">{{ WCBJ.temperature2 }}</van-cell>
            </van-cell-group>
            <el-row style="margin-top: 5px;">
                <van-cell title="报警时间">{{ WCBJ.createTime }}</van-cell>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/css/digital.css";
Vue.use(Vant);
export default {
    props: ["id"],
    data() {
        return {
            WCBJ: null,
            wxcW81_BJ_WCBJ: null,
            activeNames: ["1", "2"],
        };
    },
    methods: {
        getWCBJ() {
            let that = this;
            that.axios
                .post("WXCW81/WCBJ_Record_GetOne", {
                    onlyInt: that.id,
                })
                .then((response) => {
                    console.log(response);
                    if (response.data.data !== null) {
                        that.WCBJ = response.data.data;
                        that.wxcW81_BJ_WCBJ = response.data.data.wxcW81_BJ_WCBJ;
                    }
                });
        },
    },
    mounted() {
        document.title = "温差报警";
        let that = this;
        that.getWCBJ();
    },
};
</script>

<style scoped>
.card {
    background-color: #409eff;
}

.font {
    font-size: 1em;
}

.fontsize {
    font-size: 1.2em;
    color: red;
}
</style>